import {
  Box,
  IconButton,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import useFetch from '../../api/useFetch';
import TypeSkeleton from '../../components/form/form-fields/TypeSkeleton';
import Header from '../../components/special/Header';
import { tokens } from '../../global/theme/tokens';
import UserTimeOverview from './user-entries/UserTimeOverview';
import { useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { DBRequest, useConfig } from '../../api/api';
import moment from 'moment';

const TimeManager = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const config = useConfig();

  const { apiData, setApiData } = useFetch(`users/time-tracking/overview`);

  const [period, setPeriod] = useState('day');
  const [startDate, setStartDate] = useState(moment().format());

  const periodMap = {
    day: {
      key: 'days',
      value: 7,
      format: 'ddd',
      prefix: '',
      endOf: 'week',
      startOf: 'week',
    },
    week: {
      key: 'weeks',
      value: 12,
      format: 'w',
      prefix: '',
      endOf: 'week',
      startOf: 'week',
    },
    month: {
      key: 'months',
      value: 12,
      format: 'MMM',
      prefix: '',
      endOf: 'month',
      startOf: 'month',
    },
  };

  function handleChange(e) {
    const reqPeriod = e.target.value;

    reqData(moment().format(), reqPeriod);
    setPeriod(reqPeriod);
  }

  function addPeriod() {
    const newStartDate = moment(startDate).add(
      periodMap[period].value,
      periodMap[period].key
    );
    setStartDate(newStartDate);
    reqData(newStartDate, period);
  }

  function subtractPeriod() {
    const newStartDate = moment(startDate).subtract(
      periodMap[period].value,
      periodMap[period].key
    );
    setStartDate(newStartDate);
    reqData(newStartDate, period);
  }

  function reqData(defaultStartDate, reqPeriod) {
    const reqEndDate = moment(defaultStartDate)
      .endOf(periodMap[reqPeriod].endOf)
      .format('YYYY-MM-DD');
    const reqStartDate = moment(defaultStartDate)
      .startOf(periodMap[reqPeriod].startOf)
      .subtract(periodMap[reqPeriod].value, periodMap[reqPeriod].key)
      .format('YYYY-MM-DD');

    DBRequest({
      config,
      path: `users/time-tracking/overview?period=${reqPeriod}&startDate=${reqStartDate}&endDate=${reqEndDate}`,
      onResponse: setApiData,
    });
  }

  return (
    <>
      <Header title={t('Time Manager')} />
      <Box
        className="flex justify-between items-center px-2 py-1 rounded-lg backdrop-blur-lg"
        sx={{
          bgcolor: colors.glass,
        }}
      >
        <IconButton onClick={subtractPeriod}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        <Box>
          <ToggleButtonGroup
            size="small"
            value={period}
            exclusive
            onChange={handleChange}
            aria-label="Time Period"
          >
            <ToggleButton value="day">{t('Day')}</ToggleButton>
            <ToggleButton value="week">{t('Week')}</ToggleButton>
            <ToggleButton value="month">{t('Month')}</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        <IconButton onClick={addPeriod}>
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
      {apiData ? (
        <Box
          className="flex flex-col h-full pb-2 md:pb-0 overflow-auto px-1 sm:px-0 rounded-lg backdrop-blur-lg"
          sx={{
            bgcolor: colors.glass,
          }}
        >
          {apiData.data.map((data) => (
            <UserTimeOverview
              key={data.id}
              data={data}
              period={period}
              format={periodMap[period].format}
              prefix={periodMap[period].prefix}
            />
          ))}
        </Box>
      ) : (
        <TypeSkeleton n={10} />
      )}
    </>
  );
};

export default TimeManager;
