import { Box, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { tokens } from '../../../../global/theme/tokens';
import { useTranslation } from 'react-i18next';
import { renderHours } from '../../../time-tracking/worktime';
import { useEffect, useState } from 'react';

export const TimeElement = ({
  time,
  format,
  height,
  maxValue,
  activeTime,
  onMaxValueChange,
  prefix = '',
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const label = prefix + moment(time.date).format(format);
  const { t } = useTranslation();

  const [timeInSeconds, setTimeInSeconds] = useState(
    parseInt(time.totalTimeSeconds)
  );

  const percent = timeInSeconds ? timeInSeconds / maxValue : 0;

  const labelHeight = 14;
  const barHeight = (height - labelHeight) * percent;

  useEffect(() => {
    calcNewTimes(activeTime);
    const tickInterval = setInterval(() => calcNewTimes(activeTime), 1000);
    return () => clearInterval(tickInterval);
  }, [activeTime]);

  function calcNewTimes(timeElement) {
    if (timeElement) {
      const newSecondsIn = moment().diff(
        moment(timeElement.timeStart),
        'seconds'
      );

      const newTotalTimeSeconds =
        newSecondsIn + parseInt(time.totalTimeSeconds);
      setTimeInSeconds(newTotalTimeSeconds);
      if (newTotalTimeSeconds > parseFloat(maxValue)) {
        onMaxValueChange(newTotalTimeSeconds);
      }
    }
  }

  const color = activeTime
    ? colors.success // is currently working
    : percent > 0
    ? colors.info // has worked
    : colors.selected; // wasnt working

  return (
    <Box className="flex flex-col-reverse justify-between @container">
      <Box className={`flex flex-col justify-end`}>
        <Box
          className="flex flex-col justify-end"
          sx={{
            height: `${height - labelHeight}px`,
          }}
        >
          <Box
            sx={{
              bgcolor: color + '80',
              height: `${barHeight}px`,
              border: `1px  solid ${color}`,
              transition: 'height 0.6s ease',
            }}
            className="w-full rounded-t-md animate-timebar"
          />
        </Box>
        <Typography variant="h6" fontSize={10} textAlign="center">
          {t(label)}
        </Typography>
      </Box>
      {Boolean(timeInSeconds) && timeInSeconds > 0 && (
        <Typography
          fontSize={10}
          textAlign="center"
          variant="h6"
          className="hidden monospace"
          sx={{
            height: `${labelHeight}px`,
            '@container (min-width: 35px)': {
              display: 'block',
            },
          }}
        >
          {renderHours(timeInSeconds / 60)}h
        </Typography>
      )}
    </Box>
  );
};
