import {
  useTheme,
  Box,
  Typography,
  Avatar,
  IconButton,
  Link,
  Tooltip,
} from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import ExoAvatar from "../../exo/ExoAvatar";
import CardContainer from "../layout/CardContainer";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";
import { useNavigate } from "react-router-dom";

const PictureNameCard = ({
  title = null,
  pictureUrl = "",
  name,
  span = 1,
  avatarSize = "60px",
  mail = null,
  phone = null,
  url = null,
  type = "contact",
  position = null,
  color,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const navigate = useNavigate();

  return (
    <CardContainer /*onClick={() => navigate(`/contacts/id`)}*/ span={span}>
      {title && <Typography variant="h6">{title}</Typography>}

      <ExoAvatar
        size={avatarSize}
        picture={pictureUrl}
        name={name}
        type={type}
        color={color}
      />
      <Typography variant="h4" textAlign="center">
        {name}
      </Typography>
      {position && (
        <Typography
          variant="h5"
          sx={{ color: colors.greenAccent[400] }}
          textAlign="center"
        >
          {position}
        </Typography>
      )}

      <Box className="flex flex-row justify-between w-full">
        {phone && (
          <Tooltip title={phone}>
            <Link
              href={"tel:" + phone}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton>
                <CallIcon />
              </IconButton>
            </Link>
          </Tooltip>
        )}

        {url && (
          <Tooltip title={url}>
            <Link href={url} target="_blank" rel="noopener noreferrer">
              <IconButton>
                <LanguageIcon />
              </IconButton>
            </Link>
          </Tooltip>
        )}

        {mail && (
          <Tooltip title={mail}>
            <Link
              href={"tel:" + mail}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton>
                <EmailIcon />
              </IconButton>
            </Link>
          </Tooltip>
        )}
      </Box>
    </CardContainer>
  );
};

export default PictureNameCard;
